import axios, { AxiosResponse } from "axios";
import { Buffer } from "buffer";
import {
  CatalogItem,
  DownloadClientCatalogResponse,
  DuplicateCatalogItem,
  ItemsPaginated,
} from "../external";
import { CatalogItemCreation } from "../pages/catalog/new-catalog";
import { EbayCategory } from "./ebay-categories";

export interface CatalogFilters extends Filters {
  priceMin?: number;
  priceMax?: number;
  priceFixed?: number;
  text?: string;
  clientId?: string;
  archived?: string;
  quarantine?: boolean;
  ebayCategory?: EbayCategory[];
  suitableForListing?: boolean;
}

export interface Filters {
  count?: number;
  page?: number;
  sortField: string;
  sortDirection: "asc" | "desc";
}

const quarantineQuery = {
  should: [
    {
      bool: {
        must_not: [
          {
            exists: {
              field: "name",
            },
          },
        ],
      },
    },
    {
      bool: {
        must: [
          {
            term: {
              "name.keyword": "",
            },
          },
        ],
      },
    },
    {
      bool: {
        must_not: [
          {
            exists: {
              field: "retailPricePence",
            },
          },
        ],
      },
    },
    {
      bool: {
        must: [
          {
            term: {
              "retailPricePence.keyword": 0,
            },
          },
        ],
      },
    },
    {
      bool: {
        must_not: [
          {
            exists: {
              field: "weightGrams",
            },
          },
        ],
      },
    },
    {
      bool: {
        must: [
          {
            term: {
              "weightGrams.keyword": "",
            },
          },
        ],
      },
    },
    {
      bool: {
        must_not: [
          {
            exists: {
              field: "description",
            },
          },
        ],
      },
    },
    {
      bool: {
        must: [
          {
            term: {
              "description.keyword": "",
            },
          },
        ],
      },
    },
    {
      bool: {
        must_not: [
          {
            exists: {
              field: "clientImageLinks",
            },
          },
        ],
      },
    },
    {
      bool: {
        must_not: [
          {
            exists: {
              field: "clientSkuHyperlinks",
            },
          },
        ],
      },
    },
    {
      bool: {
        must_not: [
          {
            exists: {
              field: "dimensions.boxes",
            },
          },
        ],
      },
    },
    {
      bool: {
        must_not: [
          {
            exists: {
              field: "dimensions.boxes.height",
            },
          },
          {
            exists: {
              field: "dimensions.boxes.width",
            },
          },
          {
            exists: {
              field: "dimensions.boxes.depth",
            },
          },
        ],
      },
    },
    {
      function_score: {
        query: {
          exists: {
            field: "dimensions.boxes",
          },
        },
        functions: [
          {
            script_score: {
              script: {
                lang: "painless",
                source:
                  "double totalCubicCentimeters = 0; for (box in params['_source']['dimensions']['boxes']) { totalCubicCentimeters += box['length'] * box['width'] * box['height']; } double totalCubicMetres = totalCubicCentimeters / 1000000; return totalCubicMetres;",
              },
            },
          },
        ],
        min_score: 5,
      },
    },
  ],
};

export const getCatalogItems = async (
  props: CatalogFilters,
  authToken: string
): Promise<ItemsPaginated<CatalogItem>> => {
  let {
    queryFilterShould,
    queryFilterMust,
    queryFilterText,
    sort,
  }: {
    queryFilterShould: any[];
    queryFilterMust: any[];
    queryFilterText: any[];
    sort: any;
  } = createSearchFilters(props);

  const fetchCatalogItemsUrl = process.env
    .REACT_APP_GET_CATALOG_ITEMS_URL as string;

  const fetchCatalogItemsResult = await axios.post(
    fetchCatalogItemsUrl,
    createSearchRequestBody(
      props,
      queryFilterShould,
      queryFilterMust,
      queryFilterText,
      sort
    ),
    {
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    }
  );
  return fetchCatalogItemsResult.data;
};

export interface GetClientsCatalogProps {
  authToken: string;
  clientId?: string;
  searchFields?: Filters;
  format?: string;
}

export const getClientsCatalog = async ({
  authToken,
  clientId,
  searchFields,
  format = "default",
}: GetClientsCatalogProps) => {
  let requestBody:
    | { clientId: string; format: string }
    | { searchFilters: any };
  if (searchFields && !clientId) {
    let {
      queryFilterShould,
      queryFilterMust,
      queryFilterText,
      sort,
    }: {
      queryFilterShould: any[];
      queryFilterMust: any[];
      queryFilterText: any[];
      sort: any;
    } = createSearchFilters(searchFields);

    const searchRequest = createSearchRequestBody(
      searchFields,
      queryFilterShould,
      queryFilterMust,
      queryFilterText,
      sort
    );
    requestBody = {
      searchFilters: searchRequest,
      format,
    };
  } else if (clientId && !searchFields) {
    requestBody = {
      clientId: clientId,
      format,
    };
  } else {
    throw new Error("One of clientId or searchFields must be provided");
  }

  const fetchClientCatalogUrl = process.env
    .REACT_APP_GET_CLIENT_CATALOG_URL as string;

  const fetchCatalogItemsResult = await axios.post(
    fetchClientCatalogUrl,
    requestBody,
    {
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    }
  );

  return fetchCatalogItemsResult as AxiosResponse<DownloadClientCatalogResponse>;
};

export const getCatalogItem = async (
  authToken: string,
  itemId: string
): Promise<CatalogItem> => {
  let fetchCatalogItemsUrl = process.env
    .REACT_APP_GET_CATALOG_ITEM_URL as string;
  const requestUrl = fetchCatalogItemsUrl.replaceAll(":itemId:", itemId);

  const fetchCatalogItemResult = await axios.get(requestUrl, {
    headers: {
      Authorization: `Bearer ${authToken}`,
    },
  });
  return fetchCatalogItemResult.data;
};

export const getCatalogItemPublic = async (
  itemId: string
): Promise<CatalogItem> => {
  let fetchCatalogItemsUrl = process.env
    .REACT_APP_GET_CATALOG_ITEM_PUBLIC_URL as string;
  const requestUrl = fetchCatalogItemsUrl.replaceAll(":itemId:", itemId);

  const fetchCatalogItemResult = await axios.get(requestUrl);
  return fetchCatalogItemResult.data;
};

export const getCatalogTemplateUploadStatuses = async (
  authToken: string,
  date: string
) => {
  const url = process.env.REACT_APP_GET_CATALOG_UPLOAD_STATUS_URL as string;
  const fetchResult = await axios.get(url, {
    headers: {
      Authorization: `Bearer ${authToken}`,
    },
    params: {
      date: date,
    },
  });
  return fetchResult.data;
};

export const putCatalogItem = async (
  authToken: string,
  catalogItem: CatalogItem
) => {
  let url = process.env.REACT_APP_PUT_CATALOG_ITEM_URL as string;
  url = url.replaceAll(":itemId:", catalogItem.itemId);

  return await axios.put<CatalogItem, AxiosResponse<CatalogItem>, CatalogItem>(
    url,
    catalogItem,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${authToken}`,
      },
      validateStatus: () => true,
    }
  );
};

export const putCatalogItems = async (
  authToken: string,
  catalogItems: CatalogItem[]
) => {
  let url = process.env.REACT_APP_PUT_CATALOG_ITEMS_URL as string;
  return await axios.put<
    CatalogItem[],
    AxiosResponse<CatalogItem[]>,
    CatalogItem[]
  >(url, catalogItems, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${authToken}`,
    },
    validateStatus: () => true,
  });
};

export const postCatalogItem = async (
  authToken: string,
  catalogItem: CatalogItemCreation
) => {
  const url = process.env.REACT_APP_POST_CATALOG_ITEM_URL as string;
  console.info("url", { url });
  return await axios.post<CatalogItem, AxiosResponse<CatalogItem>>(
    url,
    catalogItem,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${authToken}`,
      },
    }
  );
};

export const postCatalogTemplate = async (
  authToken: string,
  clientId: string,
  uploadedFileContentsAsBase64: string,
  isMigration: boolean,
  isPriority: boolean = false
) => {
  const url = process.env.REACT_APP_POST_UPLOAD_CATALOG_TEMPLATE_URL as string;
  console.info({ url, authToken, clientId, uploadedFileContentsAsBase64 });

  const fetchResult = await axios.post(
    url,
    {
      clientId,
      csvBase64Encoded: uploadedFileContentsAsBase64,
      isMigration,
      isPriority,
    },
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${authToken}`,
      },
    }
  );

  return fetchResult.data;
};

export const postDuplicateCatalogItem = async (
  authToken: string,
  duplicateCatalogItem: DuplicateCatalogItem
) => {
  let url = process.env.REACT_APP_POST_DUPLICATE_CATALOG_ITEM_URL as string;
  url = url.replaceAll(":itemId:", duplicateCatalogItem.itemId);

  const fetchResult = await axios.post(
    url,
    {
      clientId: duplicateCatalogItem.clientId,
      clientItemId: duplicateCatalogItem.clientItemId,
    },
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${authToken}`,
      },
    }
  );
  return fetchResult as AxiosResponse<CatalogItem>;
};

export const postCatalogItemFile = async (
  authToken: string,
  clientId: string,
  itemId: string,
  fileName: string,
  purpose: string,
  uploadedFileContentsAsBase64: string
): Promise<CatalogItem> => {
  let url = process.env
    .REACT_APP_POST_UPLOAD_CATALOG_ITEM_DOCUMENT_URL as string;

  url = url.replaceAll(":itemId:", itemId);

  const contentType = fileName.split(".")[1];

  const buff = Buffer.from(uploadedFileContentsAsBase64, "base64").toString(
    "base64"
  );

  const uploaded: AxiosResponse<{ item: CatalogItem }> = await axios.post(
    url,
    {
      base64Image: buff,
      fileName,
      contentType,
      purpose,
    },
    {
      headers: {
        Authorization: authToken,
      },
    }
  );
  return uploaded.data.item;
};

export const deleteCatalogItemFile = async (
  authToken: string,
  clientId: string,
  itemId: string,
  fileName: string,
  uploadType: string
) => {
  let url = process.env
    .REACT_APP_DELETE_UPLOAD_CATALOG_ITEM_DOCUMENT_URL as string;

  url = url.replaceAll(":itemId:", itemId);
  url = url.replaceAll(":fileName:", fileName);
  url = url.replaceAll(":uploadType:", uploadType);

  const uploaded = await axios.delete(url, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${authToken}`,
    },
  });

  return uploaded.data;
};

export interface PatchCSVResponse {
  message: string;
  updatedCount: number;
  failedItems: Partial<CatalogItem>[];
  successfulItems: Partial<CatalogItem>[];
}

export const patchCatalogItemsCSV = async (
  authToken: string,
  csvData: Blob | ReadableStream<Uint8Array> | string
) => {
  const url = process.env.REACT_APP_POST_CATALOG_ITEM_UPLOAD_CSV_URL as string;

  const response = await axios.post<PatchCSVResponse>(url, csvData, {
    headers: {
      "Content-Type": "text/csv",
      Authorization: `Bearer ${authToken}`,
    },
  });

  return response.data;
};
function createSearchRequestBody(
  props: CatalogFilters,
  queryFilterShould: any[],
  queryFilterMust: any[],
  queryFilterText: any[],
  sort: any
):
  | {
      count: number | undefined;
      page: number | undefined;
      query: {
        bool: {
          should?: any[] | undefined;
          minimum_should_match?: number | undefined;
          must?: any[] | undefined;
        };
      };
      sort: any;
    }
  | undefined {
  return {
    count: props.count,
    page: props.page,
    query: {
      bool: {
        ...(props.quarantine
          ? {
              minimum_should_match: 1,
              should: quarantineQuery.should,
            }
          : {
              ...(queryFilterShould.length > 0
                ? { should: queryFilterShould }
                : {}),
              ...(queryFilterMust.length > 0 ? { must: queryFilterMust } : {}),
              ...(queryFilterText.length > 0
                ? {
                    should: queryFilterText,
                    minimum_should_match: 1,
                  }
                : {}),
            }),
      },
    },
    sort: sort,
  };
}

function createSearchFilters(props: CatalogFilters) {
  let queryFilterMust: any[] = [];
  let queryFilterShould: any[] = [];
  let queryFilterText: any[] = [];

  if (props.text !== undefined) {
    const nameParts = props.text.split(" ");

    const createWildcardQuery = (field: string, part: string) => ({
      wildcard: {
        [`${field}.keyword`]: {
          value: `*${part}*`,
          case_insensitive: true,
        },
      },
    });

    const fieldsToFilter = [
      "imageTags.items.parents",
      "imageTags.items.name",
      "clientItemId",
      "colour",
      "name",
      "productCategory",
      "clientCategory",
      "description",
    ];

    nameParts.forEach((part) => {
      const partQueries = fieldsToFilter.map((field) =>
        createWildcardQuery(field, part)
      );
      queryFilterText.push(...partQueries);
    });
  }

  const ebayCategoryIds: string[] = [];

  if (props.ebayCategory !== undefined && props.ebayCategory.length > 0) {
    props.ebayCategory.forEach((category) => {
      ebayCategoryIds.push(category.id.toString());
    });
  }

  if (ebayCategoryIds.length > 0) {
    queryFilterMust.push({
      terms: {
        ebayCategory: ebayCategoryIds,
      },
    });
  }

  if (props.clientId !== undefined) {
    queryFilterMust.push({ match: { clientId: props.clientId } });
  }

  if (props.priceFixed !== undefined) {
    queryFilterMust.push({
      match: { retailPricePence: props.priceFixed * 100 },
    });
  } else {
    if (props.priceMin !== undefined || props.priceMax !== undefined) {
      const lte =
        props.priceMax !== undefined ? props.priceMax * 100 : undefined;
      const gte =
        props.priceMin !== undefined ? props.priceMin * 100 : undefined;

      queryFilterMust.push({
        range: {
          retailPricePence: {
            gte: gte,
            lte: lte,
          },
        },
      });
    }
  }

  if (props.archived === "1") {
    queryFilterMust.push({ match: { archived: !!Number(props.archived) } });
  }

  if (props.archived === "0") {
    queryFilterShould.push({ match: { archived: !!Number(props.archived) } });
    queryFilterShould.push({
      bool: {
        must_not: {
          exists: {
            field: "archived",
          },
        },
      },
    });
  }

  if (props.suitableForListing !== undefined) {
    if (props.suitableForListing === true) {
      queryFilterMust.push({
        match: { suitableForListing: props.suitableForListing },
      });
    } else {
      queryFilterMust.push({
        bool: {
          should: [
            {
              bool: {
                must_not: {
                  exists: {
                    field: "suitableForListing",
                  },
                },
              },
            },
            {
              term: {
                suitableForListing: false,
              },
            },
          ],
          minimum_should_match: 1,
        },
      });
    }
  }

  let sort;

  if (props.sortField !== undefined && props.sortDirection !== undefined) {
    sort = [
      {
        [props.sortField]: {
          order: props.sortDirection,
        },
      },
    ];
  } else {
    sort = [
      {
        createdAtTimestamp: {
          order: "desc",
        },
      },
    ];
  }
  return { queryFilterShould, queryFilterMust, queryFilterText, sort };
}
