import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import Col from "react-bootstrap/Col";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import Row from "react-bootstrap/Row";
import Card from "react-bootstrap/Card";
import { Client, InventoryItem, ItemsPaginated } from "../external";
import { debounce } from "lodash";
import { UserAuthenticatedContext } from "./profile";
import { InventoryFilters } from "../workers/inventory";
import { TrashFill } from "react-bootstrap-icons";

// This defines the configuration for each input field
type SearchFieldConfig = {
  key: string;
  label: string;
  type: "text" | "radio" | "checkbox" | "date";
  options?: string[]; // Used for radio or checkbox groups
};

interface DynamicSearchComponentProps {
  clients: Client[];
  isLoaded: boolean;
  setInventoryItems: (items: any) => void;
  setInventoryItemsLoaded: (loaded: boolean) => void;
  searchFields: SearchFieldConfig[];
  filters: InventoryFilters;
  setFilters: (filters: InventoryFilters) => void;
  onSearch: (
    authToken: string,
    filters: InventoryFilters
  ) => Promise<ItemsPaginated<InventoryItem>>;
  sortField: string;
  sortDirection: "asc" | "desc";
  setMeta: (meta: any) => void;
  setIsError: (isError: boolean) => void;
  setError: (error: string | null) => void;
}

const DynamicSearchComponent: React.FC<DynamicSearchComponentProps> = ({
  clients,
  isLoaded,
  setInventoryItems,
  setInventoryItemsLoaded,
  searchFields,
  filters,
  setFilters,
  onSearch,
  sortField,
  sortDirection,
  setMeta,
  setIsError,
  setError,
}) => {
  const [selectedClient, setSelectedClient] = useState<string | undefined>(
    undefined
  );
  const [searchValues, setSearchValues] = useState<Record<string, any>>({});
  const fetchInProgressRef = useRef(false);

  const userAuthenticatedContext = useContext(UserAuthenticatedContext);

  const getItems = useCallback(async () => {
    if (!userAuthenticatedContext.token) {
      return;
    }
    return await onSearch(userAuthenticatedContext.token, filters);
  }, [onSearch, filters, userAuthenticatedContext]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedSearch = useCallback(
    debounce(() => {
      const filters: InventoryFilters = {
        count: 25,
        page: 1,
        sortField: sortField,
        sortDirection: sortDirection,
        clientId: selectedClient,
        ...searchValues,
      };
      setFilters(filters);
    }, 300),
    [searchValues, sortField, sortDirection, selectedClient]
  );

  useEffect(() => {
    debouncedSearch();

    // Clean up the debounced search
    return () => debouncedSearch.cancel();
  }, [
    searchValues,
    userAuthenticatedContext,
    selectedClient,
    sortField,
    sortDirection,
    debouncedSearch,
  ]);

  useEffect(() => {
    const fetchInventoryItems = async () => {
      if (fetchInProgressRef.current || !userAuthenticatedContext.token) {
        return;
      }

      fetchInProgressRef.current = true;
      try {
        const items = await getItems();
        if (items) {
          setInventoryItems(items.data);
          setMeta(items.meta);
        }
      } catch (error) {
        setIsError(true);
        setError("Error fetching inventory items:");
      } finally {
        fetchInProgressRef.current = false;
        setInventoryItemsLoaded(true);
      }
    };

    fetchInventoryItems();
  }, [
    getItems,
    userAuthenticatedContext.token,
    setInventoryItems,
    setInventoryItemsLoaded,
    setMeta,
    setIsError,
    setError,
  ]);

  // Initial state changes outside of useEffect
  useEffect(() => {
    setInventoryItems([]);
    setInventoryItemsLoaded(false);
  }, [setInventoryItems, setInventoryItemsLoaded]);

  const handleInputChange = (key: string, value: string | boolean) => {
    setSearchValues((prev) => {
      const newSearchValues = {
        ...prev,
        [key]: value,
      };
      console.log("Updated search values:", newSearchValues);
      if (value === "") {
        delete newSearchValues[key];
      }

      return newSearchValues;
    });
  };

  const handleClear = () => {
    setSearchValues({});
    setSelectedClient(undefined);
  };

  const handleClientChange = (clientId?: string) => {
    setSelectedClient(clientId);
  };

  const handleClientClear = () => {
    setSelectedClient(undefined);
  };

  const renderInputField = (field: SearchFieldConfig) => {
    switch (field.type) {
      case "text":
        return (
          <Form.Control
            type="text"
            value={searchValues[field.key] || ""}
            onChange={(e) => handleInputChange(field.key, e.target.value)}
            disabled={!isLoaded}
          />
        );
      case "radio":
        return (
          <div>
            {field.options?.map((option) => (
              <Form.Check
                key={option}
                inline
                label={option}
                type="radio"
                name={field.key}
                value={option}
                checked={searchValues[field.key] === option}
                onChange={(e) => handleInputChange(field.key, e.target.value)}
                disabled={!isLoaded}
              />
            ))}
          </div>
        );
      case "checkbox":
        return (
          <div>
            {field.options?.map((option) => (
              <Form.Check
                key={option}
                inline
                label={option}
                type="checkbox"
                value={option}
                checked={searchValues[field.key]?.includes(option) || false}
                onChange={(e) => {
                  const newValue = e.target.checked
                    ? [...(searchValues[field.key] || []), option]
                    : searchValues[field.key].filter(
                        (val: string) => val !== option
                      );
                  handleInputChange(field.key, newValue);
                }}
                disabled={!isLoaded}
              />
            ))}
          </div>
        );
      case "date":
        return (
          <Form.Control
            type="date"
            value={searchValues[field.key] || ""}
            onChange={(e) => handleInputChange(field.key, e.target.value)}
            disabled={!isLoaded}
          />
        );
      default:
        return null;
    }
  };

  const getClientDropdownTitle = () => {
    const client = clients.find((c) => c.clientId === selectedClient);

    if (client) {
      return `${client.clientName} (${client.clientId})`;
    } else {
      return "All Clients";
    }
  };

  return (
    <Card style={{ width: "100%" }}>
      <Card.Body>
        <Form>
          <Row>
            <Col xs={12} className="d-flex">
              <div
                onClick={handleClear}
                style={{ cursor: "pointer", marginBottom: "10px" }}
              >
                <TrashFill /> Clear All
              </div>
            </Col>
          </Row>
          <hr />
          <Row>
            <Col xs={12}>
              <Form.Label>Client</Form.Label>
              <InputGroup className="mb-3">
                <DropdownButton
                  disabled={!isLoaded}
                  variant="outline-secondary"
                  title={getClientDropdownTitle()}
                >
                  <Dropdown.Item key="all" onClick={() => handleClientClear()}>
                    <b>All Clients</b>
                  </Dropdown.Item>
                  <Dropdown.Divider />
                  {clients
                    .sort((a, b) => a.clientName.localeCompare(b.clientName))
                    .map((client) => (
                      <Dropdown.Item
                        key={client.clientId}
                        onClick={() => handleClientChange(client.clientId)}
                      >
                        {client.clientName} ({client.clientId})
                      </Dropdown.Item>
                    ))}
                </DropdownButton>
              </InputGroup>
            </Col>
          </Row>
          <hr />

          {/* Dynamically render search fields */}
          {searchFields.map((field) => (
            <Row key={field.key}>
              <Col xs={12}>
                <Form.Label>{field.label}</Form.Label>
                {renderInputField(field)}
              </Col>
            </Row>
          ))}
        </Form>
      </Card.Body>
    </Card>
  );
};

export default DynamicSearchComponent;
