import { useNavigate } from "react-router";
import { InventoryItem } from "../../external/inventory-component/types";

export interface InventoryRowProps {
  inventoryItem: InventoryItem;
}

const InventoryRowComponent = (props: InventoryRowProps): JSX.Element => {
  const navigate = useNavigate();
  const { inventoryItem } = props;

  return (
    <tr
      onClick={() => {
        navigate(`/inventory/item?itemId=${inventoryItem.id}`);
      }}
    >
      <td>
        <code>{inventoryItem.id}</code>
      </td>
      <td>{inventoryItem.clientId}</td>
      <td>{inventoryItem.subClientId}</td>
      <td>{inventoryItem.loadReferenceId}</td>
      <td>{inventoryItem.clientItemId}</td>
      <td
        dangerouslySetInnerHTML={{ __html: inventoryItem.description ?? "" }}
      ></td>
      <td>
        {inventoryItem.arrivalDate
          ? new Date(inventoryItem.arrivalDate).toDateString()
          : ""}
      </td>
    </tr>
  );
};

export default InventoryRowComponent;
